<template>
    <modal ref="modalRedesSociales" titulo="Editar redes sociales" no-aceptar adicional="Guardar" @adicional="editarDatosRedes">
        <div class="row mx-0 justify-center mb-4">
            <div class="col-10">
                <small class="pl-3">
                    Facebook
                </small>
                <el-input v-model="redesSociales.facebook" class="w-100" placeholder="URL" type="url" />
            </div>
        </div>
        <div class="row mx-0 justify-center mb-4">
            <div class="col-10">
                <small class="pl-3">
                    Instagram
                </small>
                <el-input v-model="redesSociales.instagram" class="w-100" placeholder="URL" />
            </div>
        </div>
        <div class="row mx-0 justify-center mb-4">
            <div class="col-10">
                <small class="pl-3">
                    WhatsApp(Indicativo país + Número de celular)
                </small>
                <el-input v-model="redesSociales.whatsapp" class="w-100" placeholder="indicativo país + Número de celular" />
            </div>
        </div>
    </modal>
</template>

<script>
import Landing from '~/services/landing/landing_admin'
export default {
    data(){
        return {
            redesSociales: {
                facebook: '',
                instagram: '',
                whatsapp: ''
            },
            facebook: '',
            instagram: ''
        }
    },
    computed: {
        idLanding(){
            return this.$route.params.idLanding
        }
    },
    methods: {
        toggle(){
            this.getRedesSocialesLanding();
            this.$refs.modalRedesSociales.toggle();
        },
        async getRedesSocialesLanding(){
            try{
                const { data } = await Landing.getRedesSociales(this.idLanding)
                if(data.redesSociales != null){
                    this.redesSociales= {
                        facebook : data.redesSociales.facebook ,
                        whatsapp : data.redesSociales.whatsapp,
                        instagram : data.redesSociales.instagram
                    }
                }
            }catch(error){
                this.error_catch(error)
            }
        },
        async editarDatosRedes(){
            try {
                
                if(this.redesSociales.facebook != null && this.redesSociales.facebook.charAt(0)== 'h' && this.redesSociales.facebook.charAt(1)== 't' && this.redesSociales.facebook.charAt(2)== 't' && this.redesSociales.facebook.charAt(3)== 'p' ){
                    this.facebook = this.redesSociales.facebook
                }else if(this.redesSociales.facebook != null && this.redesSociales.facebook.length > 0 ){
                    this.facebook = 'http://' + this.redesSociales.facebook
                }
                else{
                    this.facebook = null
                }

                if(this.redesSociales.instagram != null &&  this.redesSociales.instagram.charAt(0)== 'h' && this.redesSociales.instagram.charAt(1)== 't' && this.redesSociales.instagram.charAt(2)== 't' && this.redesSociales.instagram.charAt(3)== 'p' ){
                    this.instagram = this.redesSociales.instagram
                }else if(this.redesSociales.instagram != null && this.redesSociales.instagram.length > 0 ){
                    this.instagram = 'http://' + this.redesSociales.instagram
                }else{
                    this.instagram = null
                }

                const payload = {
                    facebook : this.facebook,
                    instagram : this.instagram,
                    whatsapp : this.redesSociales.whatsapp
                }
                const { data } = await Landing.editarRedesLanding(payload, this.idLanding)
                this.notificacion('Éxito', data.mensaje, 'success')
                this.$refs.modalRedesSociales.toggle();
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.options-create{
    height: 48px;border: 
    1px solid #DFE4E8;
    border-radius: 8px;
}
</style>